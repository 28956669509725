import React from 'react';
import NavBar from './NavBar';
import Studio from './Studio';
import About from './About';
import Angebot from './Angebot';
import Preise from './Preise';
import Kontakt from './Kontakt';
import './App.scss';

// Funktion zum manuellen Scrollen, um Safari-Probleme zu umgehen
const scrollElementIntoView = (element, behavior = 'auto') => {
  let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  
  // Höhe des Headers (falls vorhanden) wird berücksichtigt
  const headerHeight = document.querySelector('.header')?.clientHeight || 0;
  
  const finalOffset = element.getBoundingClientRect().top + scrollTop - headerHeight;

  window.scrollTo({
    top: finalOffset,
    behavior: behavior
  });
};

function App() {
  const images = ['/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg', '/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg']; // Bilderreihe mit doppeltem Satz für nahtlose Animation

  // Funktion, um zum Kontakt-Abschnitt zu scrollen
  const scrollToContact = () => {
    const contactSection = document.getElementById('kontakt');
    if (contactSection) {
      scrollElementIntoView(contactSection, 'smooth');
    }
  };

  // Funktion, um zum Studio-Abschnitt zu scrollen
  const scrollToStudio = () => {
    const studioSection = document.getElementById('studio');
    if (studioSection) {
      scrollElementIntoView(studioSection, 'smooth');
    }
  };

  return (
    <div className="App">
      {/* Navigation Bar */}
      <NavBar />

      {/* Welcome Content with sliding images */}
      <div className="content" id="welcome">
        <div className="sliding-image-container">
          <div className="sliding-images">
            {images.map((image, index) => (
              <div
                key={index}
                className="image"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            ))}
          </div>
        </div>
        <div className="content-text">
          <h1>WILLKOMMEN</h1>
          <h2>
            Ihre Expertin<br></br>für Schönheit und Wohlbefinden<br></br>
          im Herzen von Oberburg
          </h2>
          <button className="button" onClick={scrollToContact}>TERMIN BUCHEN</button>
          <div className="scroll-arrow" onClick={scrollToStudio}>
            &#x25BC;
          </div>
        </div>
      </div>

      {/* Other sections */}
      <div className="content" id="studio">
        <Studio />
      </div>

      <div className="content" id="about">
        <About />
      </div>

      {/* <div className="content" id="angebot">
        <Angebot />
      </div> */}

      <div className="content" id="preise">
        <Preise />
      </div>

      <div className="content" id="kontakt">
        <Kontakt />
      </div>
    </div>
  );
}

export default App;
