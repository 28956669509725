import React from 'react';
import './About.css';

function About() {
  return (
    <div className="section about-section">
      <h1>Über Mich</h1>
      <div className="about-container">
        <img src="/img/placeholder-portrait.jpg" alt="Kosmetikerin Portrait" className="about-portrait" />
        <div className="about-text">
          <h2>Willkommen!</h2>
          <p>
            Ich heisse Christine, Ihre Kosmetikerin im Herzen von Oberburg. Mit über 30 Jahren Erfahrung 
            biete ich jedem meiner Kunden ein besonderes Pflegeerlebnis.
          </p>
          <p>
            Mein Ziel ist es, dass Sie sich wohlfühlen und Ihre natürliche Schönheit erstrahlt.
          </p>
          <p>
            Ich freue mich, Sie bald bei mir zu begrüssen!
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
