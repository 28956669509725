import React from 'react';
import './App.scss';

function Kontakt() {
  return (
    <div className="section">
      <h1>Termin</h1>

      {/* Bild als anklickbares Objekt mit dem WhatsApp-Link */}
      <a href="https://wa.me/qr/PSREAP4AHMBTG1" target="_blank" rel="noopener noreferrer">
        <img src="/img/qr.png" alt="Contact" style={{ cursor: 'pointer' }} />
      </a>
      
      <p>Scannen Sie den angezeigten QR-Code oder klicken Sie darauf, um mich direkt über WhatsApp hinzuzufügen und einen Termin nach Vereinbarung zu buchen.</p>
      <p>Oder rufen Sie an </p>
      <a href="tel:+41797116662" style={{ textDecoration: 'none', color: 'inherit' }}>
        <h2>+41 79 711 66 62</h2>
      </a>
      <p>und hinterlassen Sie Name, gewünschter Service und Wunschtermin. Ich melde mich umgehend!</p>

      {/* Button for saving the contact with the same style as "Termin Buchen" */}
      <a href="data:text/vcard;charset=utf-8,BEGIN:VCARD%0AVERSION:3.0%0AFN:Christine%20Wyss-Fischer%20KosmetikStudio%20Oberburg%0ATEL:+41797116662%0AEND:VCARD" download="Christine_Wyss-Fischer_KosmetikStudio_Oberburg.vcf" style={{ textDecoration: 'none', color: 'inherit' }}>
        <button className="button">Als Kontakt speichern</button>
      </a>
    </div>
  );
}

export default Kontakt;
