import React, { useState } from 'react';
import './Studio.css';

function Studio() {
  const images = ['/img/studio1.jpg', '/img/studio2.jpg', '/img/studio3.jpg'];
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  // Funktion, um das ausgewählte Bild zu öffnen
  const openImage = (index) => {
    setSelectedImage(images[index]);
    setCurrentIndex(index);
  };

  // Funktion für das nächste Bild
  const nextImage = (e) => {
    e.stopPropagation(); // Verhindert, dass das Modal geschlossen wird
    const nextIndex = (currentIndex + 1) % images.length;
    setSelectedImage(images[nextIndex]);
    setCurrentIndex(nextIndex);
  };

  // Funktion für das vorherige Bild
  const prevImage = (e) => {
    e.stopPropagation(); // Verhindert, dass das Modal geschlossen wird
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setSelectedImage(images[prevIndex]);
    setCurrentIndex(prevIndex);
  };

  // Funktion, um das Modal zu schließen
  const closeImage = () => {
    setSelectedImage(null);
  };

  return (
    <div className="section">
      <h1>STUDIO</h1>
      <p>Emmentalstrasse 36<br></br>3414 Oberburg</p>
      {/* Google Maps Iframe */}
      <div className="map-container">
        <iframe
          title="Studio Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2760.614749290991!2d7.623324816179925!3d46.94501807914678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e395469cf8f95%3A0x22a3c9645b4c2a6c!2sEmmentalstrasse%2036%2C%203414%20Oberburg%2C%20Schweiz!5e0!3m2!1sde!2sus!4v1694689321419!5m2!1sde!2sus"
          width="100%"
          height="350"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>

      {/* Bildgalerie */}
      <div className="gallery">
        {/* <h2>Galerie</h2> */}
        <div className="thumbnails">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Studio ${index + 1}`}
              className="thumbnail"
              onClick={() => openImage(index)}
            />
          ))}
        </div>

        {/* Modal für das vergrößerte Bild */}
        {selectedImage && (
          <div className="modal" onClick={closeImage}>
            <span className="close" onClick={closeImage}>
              &times;
            </span>
            <img className="modal-content" src={selectedImage} alt="Selected" />
            <div className="caption">
              {currentIndex + 1} / {images.length}
            </div>
            <button className="prev" onClick={prevImage}>
              &#10094;
            </button>
            <button className="next" onClick={nextImage}>
              &#10095;
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Studio;
